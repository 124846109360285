





























































































































































@import "../../assets/css/table";

.flex {
	align-items: normal;
}
